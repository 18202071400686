const salesStatus = {
  invisible: {
    label: '임시저장',
    colorClass: 'grey',
  },
  display: {
    label: '전시중',
    colorClass: 'light-blue',
  },
  sale: {
    label: '판매중',
    colorClass: 'skyblue',
  },
  time_out: {
    label: '유찰',
    colorClass: 'grey',
  },
  bid_payment_time_out: {
    label: '낙찰취소',
    colorClass: 'grey',
  },
  bid_payment_cancel: {
    label: '낙찰취소',
    colorClass: 'grey',
  },
  bid_complete: {
    label: '낙찰',
    colorClass: 'purple',
  },
  sold_out: {
    label: '판매완료',
    colorClass: 'black',
  },
};

const orderStatus = {
  none: {
    label: '주문없음',
    colorClass: '',
  },
  payment_wait: {
    label: '결제대기중',
    colorClass: 'red',
  },
  deposit_wait: {
    label: '입금대기중',
    colorClass: 'red',
  },
  payment_complete: {
    label: '결제완료',
    colorClass: '',
  },
  payment_time_out: {
    label: '결제시한만료',
    colorClass: 'red',
  },
  proccessed: {
    label: '배송준비중',
    colorClass: 'green',
  },
  shipped: {
    label: '배송중',
    colorClass: 'green',
  },
  delivered: {
    label: '배송완료',
    colorClass: 'green',
  },
  purchase_cancel: {
    label: '낙찰취소',
    colorClass: 'grey',
  },
  return_request: {
    label: '반품요청',
    colorClass: 'grey',
  },
  return_complete: {
    label: '반품확정',
    colorClass: 'grey',
  },
  complete: {
    label: '구매확정',
    colorClass: 'black',
  },
};

export default function salesAndOrderStatus() {
  return { salesStatus, orderStatus };
}
