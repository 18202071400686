
import { defineComponent, onUpdated, PropType, ref } from 'vue';
import { format } from 'date-fns';
import router from '@/router';
import useCategoryOptions from '@/mixins/useCategoryOptions';
import SetInvoiceNumberModal from '@/components/SetInvoiceNumberModal.vue';
import partnerAPI from '@/service/partnerAPI';
import getServerErrorMessage from '@/utils/getServerErrorMessage';
import salesAndOrderStatus from '@/constants/salesAndOrderStatus';
import SortingDefaultIcon from '@/components/icons/SortingDefaultIcon.vue';
import SortingDescendingIcon from '@/components/icons/SortingDescendingIcon.vue';
import SortingAscendingIcon from '@/components/icons/SortingAscendingIcon.vue';


export default defineComponent({
  name: 'ListTable',
  components: {
    SetInvoiceNumberModal,
    SortingAscendingIcon,
    SortingDefaultIcon,
    SortingDescendingIcon
  },
  props: {
    products: Object as PropType<any>,
    sortBy : String
  },
  setup: function (props, { emit }) {
    const { categoryDepth1Obj, categoryDepth2Obj } = useCategoryOptions();
    const { salesStatus, orderStatus } = salesAndOrderStatus();
    const isOpenInvoiceNumber = ref(false);
    const targetProduct = ref(null);

    const sort = ref(
      {
        lot: 'NONE',
        startDate: 'NONE',
        startPrice: 'NONE',
        buynowPrice: 'NONE',
        nowPrice: 'NONE',
        biddingCount: 'NONE',
      }
    )
    if (props.sortBy) {
      const [key, value] = props.sortBy.split(':')
      if (key && value) {
        sort.value[key] = value
      }
    }

    const sortHandler = (name, value) => {
      Object.keys(sort.value).forEach(key => sort.value[key] = 'NONE')
      sort.value[name] = value
      if (value === 'NONE') {
        emit('changeSort', undefined)
        return
      }
      emit('changeSort', `${name}:${value}`)
    }

    const closeInvoiceNumber = () => {
      isOpenInvoiceNumber.value = false;
    };
    onUpdated(() => {
      console.log('products', props.products);
    });

    // 상태변경
    const onChangeStatus = (e, item) => {
      const value = e.target.value;
      if (value) {
        if (value === 'shipped') {
          targetProduct.value = item;
          isOpenInvoiceNumber.value = true;
        } else {
          console.log(item.orderStatus);
          if (
            window.confirm(
              `[${orderStatus[item.orderStatus].label}] -> [${orderStatus[value].label}] 로 변경하시겠습니까?`
            )
          ) {
            changeStatus(value, item.productId);
          }
        }
      }
      e.target.value = '';
    };

    const changeStatus = async (option, productId) => {
      try {
        console.log(option);
        const { data } =
          await partnerAPI.partnerProduct.updateOrderStatusUsingPUT({
            param: {
              productId,
              orderStatus: option,
            },
          });
        alert((data as any).message);
        emit('refresh');
      } catch (e) {
        console.error(e.response.data);
        alert(getServerErrorMessage(e));
      }
    };

    const handleClickRow = (item) => {
      // 상품상세로 이동
      router.push({
        path: `/app/sales/detail/${item.productId}`,
      });
    };

    const biddingCount = (count) => {
      return `${count} 건`
    };

    const formatNumber = (number) => {
      if (number === 0) {
        return '0원';
      }
      if (!number) {
        return '-';
      }
      return number.toLocaleString() + '원';
    };

    return {
      targetProduct,
      format,
      biddingCount,
      isOpenInvoiceNumber,
      onChangeStatus,
      handleClickRow,
      closeInvoiceNumber,
      changeStatus,
      categoryDepth1Obj,
      categoryDepth2Obj,
      formatNumber,
      salesStatus,
      orderStatus,
      sort,
      sortHandler
    };
  },
});
