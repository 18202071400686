
import { computed, defineComponent } from 'vue';
import Close from '@/components/icons/Close.vue';
import useShippingTypeOptions from '@/mixins/useShippingTypeOptions';
import partnerAPI from '@/service/partnerAPI';

export default defineComponent({
  name: 'SetInvoiceNumberModal',
  props: {
    isOpen: {
      type: Boolean,
    },
    order: {
      type: Object,
    },
    okHandler: {
      type: Function,
    },
  },
  components: {
    Close,
  },
  setup(props, { emit }) {
    const input = computed(() => {
      return {
        trackingCode: props.order?.trackingCode,
        shippingTypeId: props.order?.shippingTypeId,
      };
    });

    const { shippingTypeOptions } = useShippingTypeOptions(true);

    const setInvoiceNumber = async () => {
      try {
        await partnerAPI.partnerProduct.updateTrackingCodeUsingPUT({
          param: {
            orderId: props.order?.orderId,
            shippingTypeId: input.value.shippingTypeId,
            trackingCode: input.value.trackingCode,
          },
        });
        if (props.okHandler) {
          props.okHandler();
        }
      } catch (e) {
        alert('송장등록을 실패했습니다.');
      }
      onClose();
    };

    const onClose = () => {
      console.log('emit');
      emit('onClose');
    };

    return {
      shippingTypeOptions,
      input,
      setInvoiceNumber,
      onClose,
    };
  },
});
